import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { InPageNavigationSponsoring } from "../components/inpage-navigation"

import affilips from "../images/logo/affilips-new.png"
import wngroup from "../images/logo/wngroup.png"
import sweco from "../images/logo/sweco.png"
import denys from "../images/logo/denys.png"
import deme from "../images/logo/deme_2.jpg"
import febe from "../images/logo/febe.jpg"
import bb from "../images/logo/bb.png"
import melexis from "../images/logo/melexis.png"
import zigzag from "../images/logo/zigzag.png"
import elia from "../images/logo/elia.png"
import axa from "../images/logo/axa.svg"
import huawei from "../images/logo/huawei.png"

const Workshops = () => (
    <Layout>
        <Seo title="Sponsoring FEARS" />
        <InPageNavigationSponsoring/>
        <div className="fluid-container">
            <div className="row">
                <article id="portal-column-content" className="cell col-sm-9 mbottom-default">
                    <h1>Sponsors</h1>

                    <div id="partners" className="pbottom-default" style={{display: "flex", "flex-wrap": "wrap", "align-self": "center", "align-items": "center", gap: "40px"}}>
                        {/* <a style={{border: "none"}} href="https://www.willynaessens.be/" target="_blank" rel="noopener noreferrer"><img width={240} src={wngroup} alt="willy naesens"></img></a> */}
                        {/* <a style={{border: "none"}} href="https://www.swecobelgium.be/" target="_blank" rel="noopener noreferrer"><img width={220} src={sweco} alt="sweco"></img></a> */}
                        {/* <a style={{border: "none"}} href="https://www.melexis.com/" target="_blank" rel="noopener noreferrer"><img width={200} src={melexis} alt="melexis"></img></a> */}
                        {/* <a style={{border: "none"}} href="https://www.febe.be/" target="_blank" rel="noopener noreferrer"><img width={100} src={febe} alt="febe"></img></a> */}
                        {/* <a style={{border: "none"}} href="https://www.affilips.com/" target="_blank" rel="noopener noreferrer"><img width={200} src={affilips} alt="affilips"></img></a> */}
                        {/* <a style={{border: "none"}} href="https://b-b.be/" target="_blank" rel="noopener noreferrer"><img width={200} src={bb} alt="bb"></img></a> */}
                        <a style={{border: "none"}} href="https://denys.com/" target="_blank" rel="noopener noreferrer"><img width={220} src={denys} alt="denys"></img></a>
                        <a style={{border: "none"}} href="https://www.deme-group.com/" target="_blank" rel="noopener noreferrer"><img width={220} src={deme} alt="deme"></img></a>
                        <a style={{border: "none"}} href="https://www.elia.be" target="_blank" rel="noopener noreferrer"><img width={220} height={110} src={elia} alt="elia"></img></a>
                        {/* <a style={{border: "none"}} href="https://ziggzagg.be/" target="_blank" rel="noopener noreferrer"><img width={220} src={zigzag} alt="ziggzagg"></img></a> */}
                        <a style={{border: "none"}} href="https://www.axa.be" target="_blank" rel="noopener noreferrer"><img width={110} height={110} src={axa} alt="axa"></img></a>
                        <a style={{border: "none"}} href="https://www.huawei.com" target="_blank" rel="noopener noreferrer"><img width={110} height={110} src={huawei} alt="huawei"></img></a>
                    </div>

                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                        <h2>Become a partner of FEARS!</h2>
                            <p>On Wednesday November 27, 2024, the Faculty of Engineering and Architecture (FEA) will organize its 22nd research symposium. This edition will once again be a physical meeting platform for companies and engineering-oriented researchers. The faculty's goal is to pull research away from dusty desks or hidden labs and look for collaborations and inspiration to even better address today's problems.
                            </p>
                            <p>FEARS is a very popular event where students, researchers, valorization experts, professors and visitors all find their match. Our industrial partners play an essential role at this event. The variety of attendees provides an unique opportunity to build a solid network. Finally, the coffee breaks and workshops provide the opportunity to discuss ongoing and future collaborations. Of course, pitches/demos and posters sessions by internal and external experts will bring you up to speed on the very latest technologies within FEA.
                            </p>
                        </div>
                    </div>

                    <div className="row pbottom-default">
                        <div className="col-sm-6">
                            <h2>Previous editions</h2>
                            <p>During the previous edition, there were <strong>more than 300 participating researchers</strong>. Our faculty has a strong track record of industrial collaborations and wants to build on that experience.
                            The website, including all partner logos was sent to the <strong>entire faculty (+/- 2000 people)</strong>, the partner logos were projected throughout the day, and at the event itself there was the possibility to distribute <strong>promo material</strong>.
                            </p>
                        </div>
                        <div className="col-sm-6">
                            <h2>Current edition</h2>
                            <p>
                                The 2024 edition the theme of FEARS is <strong>research in industry</strong>.
                                The program is tailored to stimulate collaboration between academia and industry.
                            </p>
                        </div>
                    </div>


                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <h2 id="become-a-sponsor" >Partners wanted!</h2>
                            <p>We offer your company visibility to researchers, alumni and students of the faculty. Contact us for more information on the sponsorship package.
                            </p>
                        </div>
                        {/* <div className="col-sm-6">
                                <h3>Partner</h3>

                                <p>As a regular partner <strong>(€500)</strong> your company's logo will be visible and legibly presented:</p>
                                <ul>
                                    <li>on the partner slide at the start of the plenary session;</li>
                                    <li>on the partner slide, set up in the foyer of the event</li>
                                    <li>on the website of the symposium (http://www.fears.ugent.be) with a link to your company's website</li>
                                    <li>by a mentioning on LinkedIn.</li>
                                </ul>
                                <button className="btn btn-default" href="mailto:bruno.vanderschelden@ugent.be" >
                                    <a href="mailto:bruno.vanderschelden@ugent.be" activeClassName="active">Become a partner</a>
                                </button>
                            </div>
                            <div className="col-sm-6">
                                <h3>Main Partner</h3>
                                <p>As a main partner <strong>(€1000)</strong> we provide maximum visibility and active contributions:</p>
                                <ul>
                                    <li>through the same channels as the regular partners, but with a separate slide prior to the plenary
                                        sessions, its own separate slide in the foyer and an explicit mention as a main partner on this
                                        website and LinkedIn</li>
                                    <li>through the opportunity to set up your own info stand (1x2m) or give an elevator pitch on research
                                        in your company at the symposium: a chance to showcase the importance of research for your
                                        company to students, PhD students, professors and future employees…</li>
                                    <li>through explicit words of thanks at the conclusion of the event, where the awards will be presented
                                        on behalf of the main partners.</li>
                                    <li>by the logo on FEARS communication gadgets distributed in large quantities</li>
                                </ul>
                            </div>*/}
                    </div>
                    <div class="alert alert-info"><strong>Interested?</strong> Contact us at <a href="mailto:fears@ugent.be">fears@ugent.be</a></div>
                </article>
            </div>
        </div>
    </Layout>
)

export default Workshops
